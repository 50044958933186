:root {
    --color_nuvue_black: #06070a;
    --color_nuvue_dark_gray: #3c424b;
    --color_nuvue_gray: #aaa;
    --color_nuvue_light_gray: #d3d3d3;
    --color_nuvue_white: #fff;
    --color_admin_accent: #f8b34b;

    --font-heading: 'Figtree';
    --font-body: 'Figtree';

    --font_size_h1: 72px;
    --font_size_h2: 48px;
    --font_size_h3: 32px;
    --font_size_h4: 22px;
    --font_size_h5: 17px;
    --font_size_h6: 14px;
    --font_size_p: 14px;
}

$mobile-min-width: 420px;
$mobile-max-width: 480px;
$tablet-min-width: 481px;
$tablet-max-width: 940px;
$desktop-min-width: 941px;
$desktop-max-width: 1200px;
$desktop-large-min-width: 1201px;
$desktop-large-max-width: 1600px;