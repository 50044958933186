.form-group{
    padding-bottom:20px;
}

.checkbox{
    position: relative;
    cursor:pointer;
    width:30px;
    height:30px;
    background:#eee;
    border-radius:3px;
    border:6px solid #eee;

    &.checked{
        background: #333 !important;
    }

    >input{
        cursor:pointer;
        opacity: 0;
        position: absolute;
        width:100%;
        height:100%;
        top:0; left:0;
        z-index: 1;
    }
}

textarea{
    min-height: 300px;
}

.react-datepicker-wrapper{
    width:100%;
    .react-datepicker__input-container{
        width:100%;
        input[type=text]{
            border:none;
            width:100%;
        }
    }
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input[type=number] {
    -moz-appearance:textfield; /* Firefox */
}

input[type=text],input[type=number],input[type=password],input[type=email],textarea, select{
    appearance: none !important;
    -webkit-appearance: none !important;
    color: inherit;
    text-align: inherit;
    font-weight: 500;
    border-radius: 100px;
    border:1px solid #ebebeb;

    &:focus, &:hover{

      border:1px solid #444444;
    }

    &.error{
      border:1px solid #660000 !important;
    }
}

select{
  &:after{
    content:'^'
  }
}

::placeholder {
  color: inherit;
  opacity: .5;
  font-weight: inherit;
}
  
:-ms-input-placeholder {
  color: inherit;
  opacity: .5;
  font-weight: inherit;
}
  
::-ms-input-placeholder {
  color: inherit;
  opacity: .5;
  font-weight: inherit;
}

.switch {
  position: relative;
  display: inline-block;
  width: 38px;
  height: 20px;
}
  
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}
  
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
}
  
.slider:before {
  position: absolute;
  content: "";
  height: 16px;
  width: 16px;
  left: 2px;
  bottom: 2px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}
  
input:checked + .slider {
  background-color: #ED6463 !important;
}

input:focus + .slider {
  box-shadow: 0 0 1px #ED6463 !important;
}

input:checked + .slider:before {
  -webkit-transform: translateX(18px);
  -ms-transform: translateX(18px);
  transform: translateX(18px);
}
  
.slider.round {
  border-radius: 20px;
}

.slider.round:before {
  border-radius: 50%;
}