
.ar-20{
    position:relative;
    height:0; width:100%;
    padding-bottom:20%;
}
.ar-25{
    position:relative;
    height:0; width:100%;
    padding-bottom:25%;
}
.ar-30{
    position:relative;
    height:0; width:100%;
    padding-bottom:30%;
}
.ar-40{
    position:relative;
    height:0; width:100%;
    padding-bottom:40%;
}
.ar-50{
    position:relative;
    height:0; width:100%;
    padding-bottom:50%;
}
.ar-5625{
    position:relative;
    height:0; width:100%;
    padding-bottom:56.25%;
}
.ar-60{
    position:relative;
    height: 0; width:100%;
    padding-bottom: 60%;
}
.ar-75{
    position:relative;
    height:0; width:100%;
    padding-bottom:75%;
}
.ar-100{
    position:relative;
    height:0; width:100%;
    padding-bottom:100%;
}
.ar-120{
    position:relative;
    height:0; width:100%;
    padding-bottom:120%;
}