.nav{
    >a:link, >a:visited{
        text-decoration: none;
        padding: 4px 0px;
        color: var(--color_nuvue_gray) !important;
        font-weight: 700;

        &.active{

            border-bottom: 2px solid var(--color_admin_accent);
            color: var(--color_nuvue_black) !important;

            &.btn-primary{

                color: var(--color_admin_accent) !important;
                border: none !important;
                background-color: black !important;
            }
        }
    }
}