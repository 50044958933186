.w-100{
    width:100%;
}
.h-100{
    height:100%;
}
.cover{
    width:100%;
    height:100%;
}

.align-items-center{
    align-items:center;
}
.align-items-start{
    align-items: flex-start;
}
.align-content-start{
    align-content: flex-start;
}
.align-content-center{
    align-content: center;
}
.align-items-end{
    align-items: flex-end;
}
.align-items-stretch{
    align-items: stretch;
}
.justify-content-start{
    justify-content: start;
}
.justify-content-center{
    justify-content: center;
}
.justify-content-end{
    justify-content: flex-end;
}
.d-flex{
    display: flex;
}
.flex-wrap{
    flex-wrap: wrap;
}
.flex-grow-1{
    flex: 1 0;
}
.flex-column{
    flex-direction: column;
}

@media( max-width:$tablet-min-width ){
    .flex-grow-1-m{
        flex:1 1 100% !important;
    }
}

@mixin cols {
    @for $i from 1 through 12 {
        .col-#{$i} { 
            width: calc( ( #{$i} / 12 ) * 100% );
        }
    }
    // 5 cols
    .col-2-4{
        width: 20%;
    }
    // 7 cols
    .col-1-7{
        width: 14.2857%;
    }
    // 8 cols
    .col-1-5{
        width: 12.5%;
    }
    @media(min-width:$tablet-min-width){
        @for $i from 1 through 12 {
            .col-t-#{$i} { 
                width: calc( ( #{$i} / 12 ) * 100% );
            }
        }
        .col-t-2-4{
            width: 20%;
        }
        .col-t-1-7{
            width: 14.2857%;
        }
        .col-t-1-5{
            width: 12.5%;
        }
    }
    @media(min-width:$desktop-min-width){
        @for $i from 1 through 12 {
            .col-d-#{$i} { 
                width: calc( ( #{$i} / 12 ) * 100% );
            }
        }
        .col-d-2-4{
            width: 20%;
        }
        .col-d-1-7{
            width: 14.2857%;
        }
        .col-d-1-5{
            width: 12.5%;
        }
    }
}
@include cols;