@import url('https://fonts.googleapis.com/css2?family=Figtree:wght@300;400;500;600&display=swap');

html,body{
    font-family: var( --font-body ), sans-serif;
    font-weight: 400;
    font-size: 15px;
    line-height: 1.3em;
}

input,textarea,button,select{
    font-family: var( --font-body ), sans-serif;
    line-height: inherit;
    font-weight: inherit;
    font-size: inherit;
}

p{
    margin-top:0;
    margin-bottom:.2em;
}

h1,h2,h3,h4,h5,h6,
.h1,.h2,.h3,.h4,.h5,.h6{
    margin:0;
    line-height: 1;
    font-weight: 400;
    font-family: var( --font-heading ), sans-serif;
    margin-bottom: .2em;

    &.underline{
        border-bottom:1px solid currentColor;
        line-height: .9;
    }
}

.upper{
    text-transform: uppercase;
}

.small{
    font-size: 14px !important;
}

.tiny{
    font-size: 12px !important;
}

.light{
    font-weight: 300 !important;
}

.regular{
    font-weight: 400 !important;
}

.medium{
    font-weight: 500 !important;
}

.bold, b, strong{
    font-weight: 600 !important;
}

.extra-bold{
    font-weight: 700 !important;
}

.lh-0{
    line-height: 0 !important;
}

h1, .h1{
    font-size: 72px !important;
}
h2, .h2{
    font-size: 48px !important;
}
h3, .h3{
    font-size: 32px !important;
}
h4, .h4{
    font-size: 24px !important;
}
h5, .h5{
    font-size: 19px !important;
}
h6, .h6{
    font-size: 16px !important;
}

a:link, a:visited{
    text-decoration: none;
    color: inherit !important;

    transition: color .3s;

    // &:hover{
    //     color: var( --color_admin_accent ) !important;
    // }
}