.overflow-hidden{
    overflow: hidden;
}

.difference{
    mix-blend-mode: difference;
}

.overflow-y-auto{
    overflow: hidden;
    overflow-y: auto;
}

.lh-0{
    line-height: 1;
}

.lh-1{
    line-height: 1;
}

.collapse{
    display: none;

    &.show{
        display: block;
    }
}

.disable-children{
    *{
        user-select: none;
    }
}

.bg-cover{
    background-size:cover;
    background-position:center;
}
.bg-contain{
    background-size:contain;
    background-position:center;
    background-repeat: no-repeat;
}

.d-block{
    display: block;
}

.d-inline-block{
    display: inline-block;
}

.max-w-1440{
    max-width:1440px;
    margin:0 auto;
}

.text-upper{
    text-transform: uppercase !important;
}

.ul,.underline{
    text-decoration: underline !important;
}

.decoration-none{
    text-decoration: none;
}

.text-center{
    text-align: center;
}

.text-left{
    text-align: left;
}

.text-right{
    text-align: right;
}

.d-none{
    display: none;
}

.z-1{
    z-index: 1;
}

.z-2{
    z-index: 2;
}

.z-3{
    z-index: 3;
}

.z-4{
    z-index: 4;
}

.z-5{
    z-index: 5;
}

.z-6{
    z-index: 6;
}

.z-7{
    z-index: 7;
}

.border-1{
    border-width: 1px;
}

.border-1{
    border-width: 1;
}

.border-bottom-1{
    border-width: 0;
    border-bottom-width: 1px;
}

.border-solid{
    border-style: solid;
}

.border-dashed{
    border-style: dashed;
}

.rounded-3{
    border-radius: 3px;
}
.rounded-4{
    border-radius: 4px;
}
.rounded-5{
    border-radius: 5px;
}
.rounded-6{
    border-radius: 6px;
}
.rounded-7{
    border-radius: 7px;
}
.rounded-8{
    border-radius: 8px;
}
.rounded-9{
    border-radius: 9px;
}
.rounded-10{
    border-radius: 10px;
}
.pos-rel{
    position:relative;
}
.pos-abs{
    position: absolute;
}
.pos-fixed{
    position: fixed;
}

.tl-0{
    top:0; left:0;
}

.tr-0{
    top:0; right:0;
}

.bl-0{
    bottom:0; left:0;
}

.br-0{
    bottom:0; right:0;
}

.t-0{
    top:0;
}

.l-100{
    left:100%;
}

.l-0{
    left:0;
}

.btm-0{
    bottom:0;
}

.btm-n2{
    bottom:-2px;
}

.lft-50{
    left:50%;
}

.no-wrap{
    white-space: nowrap;
}

.no-select{
    user-select: none;
}

.shadow{
    box-shadow:0px 0px 10px rgba(20, 20, 20, 0.1);
}

.pointer{
    cursor: pointer;
}

.border-none{
    border:none !important;
}

.show-m{
    display: none !important;
}

.acf-map img {
    max-width: inherit !important;
}

.dragger{
    cursor: move;
}

.edit{
    cursor: text !important;
}

@media(max-width:$tablet-max-width){

    .show-m{
        display: block !important;

        &.d-flex{
            display: flex !important;
        }
    }

    .hide-m{
        display: none !important;
    }
    
    .ar-140-m{
        padding-bottom: 140% !important;

        iframe{
            width:331% !important;
            left:50%;
            transform:translateX(-50%);
            -webkit-transform:translateX(-50%)
        }
    }

    .show-d{
        display: block !important;
    }
}

@media(min-width:$tablet-min-width){
    
    .no-wrap{
        flex-wrap:nowrap;
    }
}

