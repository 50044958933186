

// admin accent
.text-admin-accent,
.text-active-admin-accent.active,
.text-hover-admin-accent:hover{
    color: var(--color_admin_accent) !important;
}
.bg-admin-accent{
    background-color: var(--color_admin_accent) !important;
}
.border-admin-accent{
    border-color: var(--color_admin_accent) !important;
}

// black
.text-black,
.text-active-black.active,
.text-hover-black:hover{
    color: #000 !important;
}
.bg-black{
    background-color: #000 !important;
}
.border-black{
    border-color: #000 !important;
}

// dark-dark-gray
.text-dark-gray,
.text-active-dark-gray.active,
.text-hover-dark-gray:hover{
    color: #777 !important;
}
.bg-dark-gray{
    background-color: var(--color_nuvue_dark_gray) !important;
}
.border-dark-gray{
    border-color: var(--color_nuvue_dark_gray) !important;
}

// dark-gray
.text-gray,
.text-active-gray.active,
.text-hover-gray:hover{
    color: var(--color_nuvue_gray) !important;
}
.bg-gray{
    background-color: var(--color_nuvue_gray) !important;
}
.border-gray{
    border-color: var(--color_nuvue_gray) !important;
}

// light-gray
.text-light-gray,
.text-active-light-gray.active,
.text-hover-light-gray:hover{
    color: var(--color_nuvue_light_gray) !important;
}
.bg-light-gray{
    background-color: var(--color_nuvue_light_gray) !important;
}
.border-light-gray{
    border-color: var(--color_nuvue_light_gray) !important;
}

.bg-extra-light-gray{
    background-color: #f6f6f6 !important;
}

// light-white
.text-white,
.text-active-white.active,
.text-hover-white:hover{
    color: var(--color_nuvue_white) !important;
}
.bg-white{
    background-color: var(--color_nuvue_white) !important;
}
.border-white{
    border-color: var(--color_nuvue_white) !important;
}

.bg-alt-white{
    &:nth-child(odd){
        background-color: #F3F3F3AA;
    }
}

.bg-alt-gray{
    &:nth-child(even){
        background-color: #DDDDDD33;
    }
}

.hover-border-admin-accent:not(:disabled):hover{

    border-color: var(--color_admin_accent) !important;
}

.hover-border-gray:not(:disabled):hover{
    
    border-color: #999 !important;
}

.text-go{
    color: #29CD57 !important;
}
.bg-go{
    background-color: rgba(122, 255, 143, 0.29) !important;
}

.text-warn{
    color: #bc5252 !important;
}