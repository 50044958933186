html,body,#root{
    padding:0;
    margin:0;
    height: 100%;
    overflow: hidden;
    background-color: #FFFFFF;
}

*{
    box-sizing: border-box;
}

hr{
    border:none;
    border-bottom:1px solid #dddddd;
    margin:0;
}

::placeholder,::-webkit-input-placeholder,::-webkit-placeholder{
    color:inherit;
}

*:focus{
    outline:none !important;
}

svg{
    user-select: none;
}

ol{
    counter-reset: olli;

    >li{
        counter-increment: olli;

        &:before{
            display: none;
        }

        >div>span{
            &:first-child{
                &:before{
                    display: inline-block;
                    content: counter(olli) ".";
                    margin-right:.5em;
                }
            }
        }
    }
}

ul>li{
    list-style: none !important;

    >div>span{
        &:first-child{
            &:before{
                display: block;
                position: absolute;
                content: '\25CF';
                color: var(--color_secondary)
            }
            >span{
                display: block;
                margin:0 0 1em 1.5em;
            }
        }
    }
}

*{
    user-select:none !important;
}

ul>li,ol>li{
    &.public-DraftStyleDefault-depth0{
        margin-right: 0 !important;
        margin-left: 0 !important;
    }
    //depth 1
    &.public-DraftStyleDefault-depth1.text-right{
        margin-right: 1em !important;
        margin-left: 0 !important;
    }
    &.public-DraftStyleDefault-depth1.text-right{
        margin-right: 1em !important;
        margin-left: 0 !important;
    }
    //depth 2
    &.public-DraftStyleDefault-depth2.text-right{
        margin-right: 2em !important;
        margin-left: 0 !important;
    }
    &.public-DraftStyleDefault-depth2.text-right{
        margin-right: 2em !important;
        margin-left: 0 !important;
    }
    //depth 3
    &.public-DraftStyleDefault-depth3.text-right{
        margin-right: 3em !important;
        margin-left: 0 !important;
    }
    &.public-DraftStyleDefault-depth3.text-right{
        margin-right: 3em !important;
        margin-left: 0 !important;
    }
    //depth 4
    &.public-DraftStyleDefault-depth4.text-right{
        margin-right: 4em !important;
        margin-left: 0 !important;
    }
    &.public-DraftStyleDefault-depth4.text-right{
        margin-right: 4em !important;
        margin-left: 0 !important;
    }
}

*{
    &.enter-active{
        opacity: 0;
    }

    &.enter-done{
        opacity: 1;
        transition: opacity .2s 0s;
    }
}

.list-folder-children{
    border-left: 3px solid #99999911;
}
.fade-in{
    opacity: 0;
}
.fade-in.ready {
    opacity: 1;
    transition: opacity .3s .2s;
}

.btn.site-nav-btn{
    background-color: white;
    color:black;
    &.active{
        color:black;
        background-color:var(--color_admin_accent)
    }
}

.site-price:not(:first-child){

    border-top:1px solid #ebebeb
}

.library-list-asset:not(.w-100){
    flex: 0 0 calc( 25% - 7.5px );
    width: calc( 25% - 7.5px );
    overflow: hidden;
}

.library-grid-asset:not(.w-100), .library-grid-folder:not(.w-100){
    flex: 0 0 calc( 33.333334% - 6.6666667px );
    width: calc( 33.333334% - 6.6666667px );
    overflow: hidden;
}

@media( max-width: 900px ){

    .library-grid-asset:not(.w-100), .library-grid-folder:not(.w-100){
        flex: 0 0 calc( 50% - 5px );
        width: calc( 50% - 5px );
    }

    .library-list-asset:not(.w-100){
        flex: 0 0 calc( 33.333334% - 6.6666667px );
        width: calc( 33.333334% - 6.6666667px );
    }
    
}

@media( max-width: 600px ){

    .library-grid-asset:not(.w-100), .library-grid-folder:not(.w-100){
        flex: 0 0 100%;
        width: 100%;
    }

    .library-list-asset:not(.w-100){
        flex: 0 0 calc( 25% - 7.5px );
        width: calc( 25% - 7.5px );
    }
    
    .library-list-asset:not(.w-100){
        flex: 0 0 calc( 50% - 5px );
        width: calc( 50% - 5px );
    }
}