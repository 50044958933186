$spacer-size : 15px;

@mixin padding {
    @for $i from 0 through 12 {
        .p-#{$i} { 
            padding:#{$i*$spacer-size} !important;
        }
        .px-#{$i} { 
            padding-left:#{$i*$spacer-size} !important;
            padding-right:#{$i*$spacer-size} !important;
        }
        .pl-#{$i} { 
            padding-left:#{$i*$spacer-size} !important;
        }
        .pr-#{$i} { 
            padding-right:#{$i*$spacer-size} !important;
        }
        .py-#{$i} { 
            padding-top:#{$i*$spacer-size} !important;
            padding-bottom:#{$i*$spacer-size} !important;
        }
        .pt-#{$i} { 
            padding-top:#{$i*$spacer-size} !important;
        }
        .pb-#{$i} { 
            padding-bottom:#{$i*$spacer-size} !important;
        }
    }

    @media(max-width:$tablet-max-width){
        @for $i from 0 through 12 {
            .p-t-#{$i} { 
                padding:#{$i*$spacer-size} !important;
            }
            .px-t-#{$i} { 
                padding-left:#{$i*$spacer-size} !important;
                padding-right:#{$i*$spacer-size} !important;
            }
            .pl-t-#{$i} { 
                padding-left:#{$i*$spacer-size} !important;
            }
            .pr-t-#{$i} { 
                padding-right:#{$i*$spacer-size} !important;
            }
            .py-t-#{$i} { 
                padding-top:#{$i*$spacer-size} !important;
                padding-bottom:#{$i*$spacer-size} !important;
            }
            .pt-t-#{$i} { 
                padding-top:#{$i*$spacer-size} !important;
            }
            .pb-t-#{$i} { 
                padding-bottom:#{$i*$spacer-size} !important;
            }
        }
    }

    @media(max-width:$mobile-max-width){
        @for $i from 0 through 12 {
            .p-m-#{$i} { 
                padding:#{$i*$spacer-size} !important;
            }
            .px-m-#{$i} { 
                padding-left:#{$i*$spacer-size} !important;
                padding-right:#{$i*$spacer-size} !important;
            }
            .pl-m-#{$i} { 
                padding-left:#{$i*$spacer-size} !important;
            }
            .pr-m-#{$i} { 
                padding-right:#{$i*$spacer-size} !important;
            }
            .py-m-#{$i} { 
                padding-top:#{$i*$spacer-size} !important;
                padding-bottom:#{$i*$spacer-size} !important;
            }
            .pt-m-#{$i} { 
                padding-top:#{$i*$spacer-size} !important;
            }
            .pb-m-#{$i} { 
                padding-bottom:#{$i*$spacer-size} !important;
            }
        }
    }
}
@include padding;

@mixin margin {
    @for $i from 0 through 12 {
        // positive in em
        .m-#{$i} { 
            margin:#{$i*$spacer-size} !important;
        }
        .mx-#{$i} { 
            margin-left:#{$i*$spacer-size} !important;
            margin-right:#{$i*$spacer-size} !important;
        }
        .ml-#{$i} { 
            margin-left:#{$i*$spacer-size} !important;
        }
        .mr-#{$i} { 
            margin-right:#{$i*$spacer-size} !important;
        }
        .my-#{$i} { 
            margin-top:#{$i*$spacer-size} !important;
            margin-bottom:#{$i*$spacer-size} !important;
        }
        .mt-#{$i} { 
            margin-top:#{$i*$spacer-size} !important;
        }
        .mb-#{$i} { 
            margin-bottom:#{$i*$spacer-size} !important;
        }
    }
    @for $i from 1 through 12 {
        // negative in px
        .m-n#{$i} { 
            margin:-#{$i*$spacer-size} !important;
        }
        .mx-n#{$i} { 
            margin-left:-#{$i*$spacer-size} !important;
            margin-right:-#{$i*$spacer-size} !important;
        }
        .ml-n#{$i} { 
            margin-left:-#{$i*$spacer-size} !important;
        }
        .mr-n#{$i} { 
            margin-right:-#{$i*$spacer-size} !important;
        }
        .my-n#{$i} { 
            margin-top:-#{$i*$spacer-size} !important;
            margin-bottom:-#{$i*$spacer-size} !important;
        }
        .mt-n#{$i} { 
            margin-top:-#{$i*$spacer-size} !important;
        }
        .mb-n#{$i} { 
            margin-bottom:-#{$i*$spacer-size} !important;
        }
    }
}
@include margin;

@mixin gaps {
    @for $i from 0 through 40 {
        .gap-#{$i} { 
            gap:#{$i*1px} !important;
        }
    }
}
@include gaps;

.ml-auto{
    margin-left:auto !important;
}
.mr-auto{
    margin-right:auto !important;
}
.mx-auto{
    margin-left:auto !important;
    margin-right:auto !important;
}
.my-auto{
    margin-top:auto !important;
    margin-bottom: auto !important;
}
.mt-auto{
    margin-top:auto !important;
}
.mb-auto{
    margin-bottom:auto !important;
}