.window-container{
    opacity: 0; top:-100vh;

    .window{
        opacity: 0;
        transition: opacity 0s;

        .content{
            opacity: 0;
            transition: opacity 0s;
        }
    }

    &.open{
        top:0; opacity: 1;

        .window{
            opacity: 1;
            transition: opacity .1s;

            .content{
                opacity: 1;
                transition: opacity .3s .1s;
            }
        }
    }
}