
button,
.btn,
input[type=file],
input[type=file]::-webkit-file-upload-button {
    background:none;
    padding:0;
    margin:0; border:none; border-radius: 0;
    margin-bottom: 0;
    line-height: .9em;
    cursor: pointer;
    text-decoration: none;
    border:none;
    color:inherit;
    transition: background-color, color;
    transition-duration: .2s, .4s;
    text-align: inherit;
}

button[disabled]{
    cursor:initial !important;
}

.btn{
    padding: 12px 20px;
    border-radius: 100px;
}

.btn.btn-context{
    color: var( --color_nuvue_dark_gray );
    display: flex;
    align-items: center;
    gap:10px;

    &:hover{
        color: var( --color_admin_accent );
    }
}

.btn.btn-primary, a.btn.btn-primary{
    color: white !important;
    background-color: var( --color_nuvue_dark_gray );
    font-weight: 600;

    &:hover{
        color: white !important;
        background-color: black;
    }
}

.btn.btn-go{
    color: black !important;
    font-weight: 600;
    background-color: #bde9b3;

    &:hover{
        color: #bde9b3 !important;
        background-color: black;
    }
}

.btn.btn-warning{
    color: rgb(176, 40, 40) !important;
    font-weight: 600;

    &:hover{
        color: white !important;
        background-color: rgb(176, 40, 40) !important;
    }
}

.btn.btn-context-menu{
    color: var( --color_nuvue_white );
    font-size: 12px;
    text-align: left;

    &:hover{
        color: var(--color_admin_accent);
    }
}

.btn.btn-light{
    padding:6px 8px 6px 8px;
    color: var( --color_nuvue_dark_gray );
    background-color: var( --color_nuvue_light_gray );
    font-weight: bold;

    &:hover{
        color: var(--color_admin_accent);
        background-color: var(--color_nuvue_dark_gray);
    }

    &.small{
        padding:6px 8px 4px 8px;
    }
}

.btn.btn-white{
    padding:8px 8px 8px 8px;
    color: var( --color_nuvue_dark_gray );
    background-color: #ffffff;
    font-weight: bold;
    box-shadow: 0px 0px 4px #00000022;

    &:hover{
        color: var(--color_admin_accent);
        background-color: var(--color_nuvue_dark_gray);
    }
}

.btn.color-swatch-btn{
    border:2px solid transparent;

    &.active{
        border:2px solid white;
    }
}

.item-context-menu-trigger{
    padding:2px 4px;
    border-radius: 3px;
    background-color:#FFFFFFCC;
    top: 2px; 
    right: 2px; 
}